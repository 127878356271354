import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export default class Text extends Component {
  render() {
    const {href} = this.props;
    const text = this.props.text || this.props.children;
    // If text contains a _ at the beginning it is considered as an intl id
    const intl = (text && text[0] === '_') ? text.substring(1) : this.props.intl;
    return (
      href ?
      <a {...this.props}>
        {intl ? <FormattedHTMLMessage id={intl} defaultMessage="" values={this.props.values} /> : text}
      </a> :
      <span {...this.props}>
        {intl ? <FormattedHTMLMessage id={intl} defaultMessage="" values={this.props.values} /> : text}
      </span>
    );
  }
}
