import React, { Component } from 'react';

import './style';

export default class Button extends Component {
    static defaultProps = {
        className: 'button'
    }

    render() {
        const { className, onClick, children, href } = this.props;
        return (
            href ?
            <a className={className} href={href}>{children}</a> :     
            <span className={className} onClick={onClick}>{children}</span>
        )
    }
}

