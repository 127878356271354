import url from 'url';
import config from '../config';
import EventEmitter from '../modules/event-emitter';
import { refreshToken } from '../service/serviceToken';
import { getBrowser } from './browser';
import Cookies from "js-cookie";

const replaceParameters = (template, parameters) => {
	let replacedTemplate = template;
	for (const parameterName in parameters) {
		if (parameters.hasOwnProperty(parameterName)) {
			const parameterRegExp = new RegExp(`{${parameterName}}`, 'g');
			replacedTemplate = replacedTemplate.replace(parameterRegExp, parameters[parameterName]);
		}
	}
	return replacedTemplate;
};

const getAPIEndpointUrl = (APIConfig, endpointName, extraParameters = {}) => {
	const staticParams = { userId: window.localStorage.getItem('auth_token') };
	const parameters = { ...APIConfig.parameters, ...extraParameters, ...staticParams };
	const urlParameters = {
		protocol: APIConfig.protocol,
		slashes: true,
		host: APIConfig.host,
		pathname: replaceParameters(APIConfig.endpoints[endpointName].pathname, parameters),
		search: replaceParameters(APIConfig.endpoints[endpointName].search || '', parameters)
	};

	return url.format(urlParameters);
};

export const getPubAPIEndpointUrl = (endpointName, extraParameters = {}) => {
	const pubAPIParameters = config.pubAPI;
	return getAPIEndpointUrl(pubAPIParameters, endpointName, extraParameters);
};


export const fetchData = (endpoint, init) => {
	const pubApiUrl = getPubAPIEndpointUrl(endpoint, init);
	return pubAPIFetch(pubApiUrl, init);
};

export const fetchJson = (endpoint, init) => {
	const pubApiUrl = getPubAPIEndpointUrl(endpoint, init);
	return pubAPIFetch(pubApiUrl, init).then((response) => response.json());
};

export const fetchResponse = (endpoint, init) => {
	const pubApiUrl = getPubAPIEndpointUrl(endpoint, init);
	return pubAPIFetch(pubApiUrl, init).then((response) => response);
};

export const pubAPIFetch = async (input, init = {}, reauth = false) => {

	let browser = getBrowser();
	let isSafari = browser === "Apple Safari";
	let auth_token = isSafari ? Cookies.get('auth_token') : window.localStorage.getItem('auth_token');
	let refresh_token = isSafari ? Cookies.get('refresh_token') : window.localStorage.getItem('refresh_token');
	let locale = window.localStorage.getItem('locale');

	let defaultHeaders = {
		'Authorization': window.localStorage.getItem('auth_token') ? 'Bearer ' + auth_token : '',
		'Accept-Language': locale
	};

	init.headers = { ...defaultHeaders, ...init.headers };

	return fetch(input, init).then(
		(response) => {

			if (response.status === 401) {

				return refreshToken(refresh_token).then((response) => {
					if (response) {
						updateToken(response);
						let updatedHeader = {
							'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token'),
						};
						init.headers = { ...init.headers, ...updatedHeader };
						return pubAPIFetch(input, init).then((response) => response);

					} else {
						EventEmitter.emit('logout');
					}
				})
			}

			if (response.status === 402) {
				response.json().then(json => {
					switch (json.error.code) {
						case 80000:
							EventEmitter.emit('paymentRequired');
							break;
						case 80001:
							EventEmitter.emit('subscriptionExpired');
							break;
						case 80002:
							EventEmitter.emit('trialExpired');
							break;
						default:
							EventEmitter.emit('paymentRequired');
							break;
					}
				})
			}

			if (response.status === 403) {
				EventEmitter.emit('forbidden');
			}

			if (response.status === 500) {
				EventEmitter.emit('serverError');
			}


			if (response.status >= 300) {
				throw response
			}

			return response;
		})
};

export const updateToken = (response) => {
	let browser = getBrowser();
	let isSafari = browser == "Apple Safari";
	let update_expire = new Date().getTime() + ((response.expires_in - 900) * 1000)
	window.localStorage.setItem('auth_token', response.access_token);
	window.localStorage.setItem('refresh_token', response.refresh_token);
	if (isSafari) {
		Cookies.set('auth_token', response.access_token);
		Cookies.set('refresh_token', response.refresh_token);
	}
};

export const getCookie = (cname) => {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return undefined
};

export const setCookie = (cname, cvalue, exdays = 1) => {
	var d = new Date();
	var days = exdays > 0 ? (exdays * 24 * 60 * 60 * 1000) : -1
	d.setTime(d.getTime() + days);
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};