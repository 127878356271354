import { fetchJson, fetchResponse } from 'helper/api';
import { getDocument } from 'service/serviceDocument';
import { getBlobUrl } from 'helper/blob'

export const getSharedArtwork = async (id) => {
  let artworkData;
  try {
    artworkData = await fetchJson("getSharedArtwork", { operaId: id })
  } catch (err) {
    console.log("err", err)
    throw err;
  }
  let response = artworkData.data;
  let blob = await getDocument(artworkData.data.main_picture);
  response.blobUrl = await getBlobUrl(blob);
  return response;
}

export const getSharedArtworks = () => {
  return fetchJson('getSharedArtworks')
    .then(async (response) => {
      return response.data = await Promise.all(response.data.map(async item => {
        await getDocument(item.main_picture)
          .then(async response => {
            item.blob_url = await getBlobUrl(response);
          });
        return item;
      })).then(response => { return response });
    })
    .catch(e => {
      console.log(e);
    });
};

export const postSharingRequest = (submitted) => {

  const formData = new FormData();
  formData.append('user', submitted.user);
  formData.append('expire', submitted.expire);
  formData.append('artwork', submitted.artwork);
  formData.append('first_name', submitted.first_name);
  formData.append('message', submitted.message);

  const init = {
    method: "POST",
    body: formData
  }
  return fetchJson('postSharingRequest', init)
    .then(response => {
      return response.data;
    })
}

export const postB2BMessage = (submitted) => {
  const formData = new FormData();
  formData.append('text_message', submitted.message);

  const init = {
    method: "POST",
    body: formData
  }
  return fetchJson('postB2BMessage', init)
    .then(response => {
      return response;
    })
}

export const getSharingHistory = () => {
  return fetchJson('getSharingHistory')
    .then(getSharingHistoryNext)
    .catch(getSharingHistoryError);
};

const getSharingHistoryNext = (response) => {
  return Promise.all(response.data.map(getBlobItem));
};

const getBlobItem = async (item) => {
  let blob = await getDocument(item.artwork.main_picture);
  item.blob_url = await getBlobUrl(blob);
  return item;
};

const getSharingHistoryError = (err) => {
  console.log('getSharingHistory_error', err);
  throw err;
};

export const removeShareArtwork = (submitted) => {

  const formData = new FormData();
  formData.append('user', submitted.user);
  formData.append('artwork', submitted.artwork);

  const init = {
    method: "DELETE",
    body: formData
  }
  return fetchResponse('removeShareArtwork', init)
    .then(response => {
      return response.data;
    })
}