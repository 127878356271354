import { UserAction } from 'action/user';

const defaultState = {
	info: null,
	subscription: {
		status: false
	}
};

export default (state = defaultState, action) => {

	switch (action.type) {
		case UserAction.SET_USER:
			return {
				...state,
				info: action.data,
				subscription: {}
			}
		case UserAction.DEL_USER:
			return {
				...state,
				info: {},
				subscription: {},
			}
		default:
			return state;
	}
};
