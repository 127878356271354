import { combineReducers } from 'redux';

import user from './user';
import artworks from './artworks';
import artwork from './artwork';
import account from './account';
import schema from './schema';
import news from './news';
import share from './share';
import ownership from './ownership';

export const appReducer = combineReducers({
	user,
	artworks,
	artwork,
	account,
	schema,
	news,
	share,
	ownership,
});

export const rootReducer = (state, action) => {
	if (action.type === 'ACCOUNT_SIGNOUT') {
		state = undefined
	}
	return appReducer(state, action)
}