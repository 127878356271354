import {fetchJson} from 'helper/api';
import {getDocument} from 'service/serviceDocument';
import {getBlobUrl} from 'helper/blob'

export const getUser = () => {
    return fetchJson('getMe')
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('err-getUser', err);
            throw err;
        });
};

export const getUsers = () => {
    return fetchJson('getUsers')
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('err-getUser', err);
            throw err;
        });
};

export const getConciergeList = () => {

    return fetchJson('getConciergeList')
        .then(getConciergeListNext)
        .catch(getConciergeListError);
};

export const getConciergeListBySubgroup = (sub_group) => {

    return fetchJson('getConciergeListBySubgroup', {sub_group: sub_group})
        .then(getConciergeListNext)
        .catch(getConciergeListError);
};

const getConciergeListNext = (fetchFunc) => {
    return Promise.all(fetchFunc.data.map(async (item) => {
        let blob = await getDocument(item.public_photo);
        item.blob_url = await getBlobUrl(blob);
        return item;
    }));
};

const getConciergeListError = (err) => {
    console.log('err-getConciergeList', err);
    throw err;
};


export const getOwnerByArtwork = (id) => {
    return fetchJson('getOwnerByArtwork', {artwork: id})
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
};

export const getSubgroupByARGroup = (ar_group) => {
    return fetchJson('getSubgroupByARGroup', {ar_group: ar_group})
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
};

export const getSubgroups = () => {
    return fetchJson('getSubgroups')
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
};