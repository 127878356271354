import { fetchJson, fetchResponse } from "helper/api";
import { getDocument } from "service/serviceDocument";
import { getBlobUrl } from "helper/blob";

const getArtwork = (id, compact = null) => {

  return fetchJson('getArtwork', { operaId: id, compact: compact })
      .then(getArtworkNext)
      .catch(err => { getArtworksError (err, 'getArtwork_error') });
};

const getArtworkNext = async (response) => {
  let item = response.data;
  let blob = await getDocument(item.main_picture);
  item.blobUrl = await getBlobUrl(blob);
  return item;
};

const getArtworks = () => {
  return fetchJson("getArtworks")
      .then(getArtworksNext)
      .catch(err => {
        getArtworksError(err, 'getArtworks_error')
      });
};

const getArtworksNext = (response) => {
  return Promise.all(response.data.results.map(getBlobItem));
};

const getBlobItem = async (item) => {
  let blob = await getDocument(item.main_picture);
  item.blob_url = await getBlobUrl(blob);
  return item;
};

const getArtworksByPage = (page = 1, page_size = 21) => {
  return fetchJson("getArtworksByPage", { page: page, page_size: page_size })
    .then(getArtworksByPageNext)
    .catch(err => { getArtworksError (err, 'getArtworksByPage_error') });
};

const getArtworksByPageNext = async (response) => {
  let pattern = "page=(.*)&";
  let nextPage = !!response.data.next ? response.data.next.match(new RegExp(pattern))['1'] : null;
  let data = await getArtworksImages(response);
  return {nextPage: nextPage, data: data};
};

const getArtworksImages = (response) => {
  return Promise.all(response.data.results.map(async (item) => {
      let blob = await getDocument(item.main_picture);
      item.blob_url = await getBlobUrl(blob);
      return item;
    }));
};

const getArtworksError = (err, err_name) => {
  console.log(err_name, err);
  throw err;
};

const postArtwork = async data => {
  const artwork_data = {
    title: data.title,
    author_name: data.author_name,
    start_year: data.start_year,
    end_year: data.end_year ? data.end_year : data.start_year,
    technique: {
      technique_type: data.technique_type,
      other_technique_type: data.other_technique_type,
      duration: data.duration,
      audio: data.audio,
      audio_language: data.audio_language,
      subtitle: data.subtitle,
      subtitle_language: data.subtitle_language,
      black_and_white: data.black_and_white,
      other_av_format: data.other_av_format,
      av_format: data.av_format
    },
    typology: {
      is_original: data.is_original,
      number: data.number,
      of: data.of,
      draft_number: data.draft_number
    },
    signature: data.signature,
    height: data.height,
    width: data.width,
    depth: data.depth,
    uom_is_metric: data.uom_is_metric,
    tag_rfid: data.tag_rfid
  };

  let dataForm = new FormData();
  dataForm.append("main_picture", data.main_picture);
  dataForm.append("artwork_data", JSON.stringify(artwork_data));

  let artworkData;

  const init = {
    method: "POST",
    body: dataForm
  };

  try {
    artworkData = await fetchJson("postArtworksBulkImage", init)
  } catch (err) {
    console.log("err", err)
    throw err;
  }
  let response = artworkData.data;
  let blob = await getDocument(artworkData.data.main_picture);
  response.blobUrl = await getBlobUrl(blob);
  return response;
};

const putArtwork = async data => {
  const artworkData = {
    financial: {
      selling_availability: data.selling_enabled && {
        enabled: data.selling_enabled,
        price: data.selling_price,
        price_currency: data.selling_currency,
        discount: 0,
        discount_currency: data.selling_currency,
        notes: data.selling_notes
      }
    },
    historical: {
      description: data.description
    }
  };
  const init = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(artworkData),
    id: data.parent_id
  };
  return fetchJson("putArtwork", init)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.log(e);
      throw e;
    });
};

const getTechniques = () => {
  return fetchJson("getTechniqueTypes")
    .then(response => {
      if (response.result === "OK") return response;
      else console.log("err-getTechinques", response);
    })
    .catch(err => console.log(err));
};

const getAvFormats = () => {
  return fetchJson("getAvFormatTypes")
    .then(response => {
      if (response.result === "OK") return response;
      else console.log("err-getTechinques", response);
    })
    .catch(err => console.log(err));
};

const getSignTypes = () => {
  return fetchJson("getSignTypes")
    .then(response => {
      if (response.result === "OK") return response;
      else console.log("err-getTechinques", response);
    })
    .catch(err => console.log(err));
};

const getAuthors = () => {
  return fetchJson("getAuthors")
    .then(response => {
      return response.data.map((param, i) => {
        return { label: param, value: i };
      });
    })
    .catch(e => {
      console.log(e);
    });
};

const getArtworkByTag = data => {
  const init = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    value: data.value
  };

  return fetchJson("getArtworkByNfc", init)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.log(e);
      throw e;
    });
};

const deleteElement = (value, elementSection, artworkId, artworkSection) => {
  const artworkData = {
    [artworkSection]: {
      [elementSection]: [
        {
          id: value.id,
          delete: true
        }
      ]
    }
  };

  const init = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(artworkData),
    id: artworkId
  };
  return fetchJson("putArtwork", init)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.log("error", e);
      throw e;
    });
};

const deleteObject = (value, elementSection, artworkId, artworkSection) => {
  const artworkData = {
    [artworkSection]: {
      [elementSection]: {
        id: value.id,
        enabled: false
      }
    }
  };

  const init = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(artworkData),
    id: artworkId
  };
  return fetchJson("putArtwork", init)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.log("error", e);
      throw e;
    });
};

const deleteArtwork = artworkId => {
  const init = {
    method: "DELETE",
    id: artworkId
  };
  return fetchResponse("deleteArtwork", init)
    .then(response => {
      return response;
    })
    .catch(e => {
      console.log("error", e);
      throw e;
    });
};

export {
  getArtworks,
  getArtworksByPage,
  getArtwork,
  getTechniques,
  getSignTypes,
  postArtwork,
  putArtwork,
  getAvFormats,
  getAuthors,
  getArtworkByTag,
  deleteElement,
  deleteObject,
  deleteArtwork
};
