import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style';

export default class Modal extends Component {

    static defaultProps = {
        className: 'modal'
    };

    render() {
        const { className } = this.props;
        // Render nothing if the "show" prop is false
        if (!this.props.show) {
            return null;
        }

        return (
            this.props.footer?
            <div className="backdrop">
                <div className={className}>
                    {this.props.children}

                    <div className="footer">
                        <button onClick={this.props.onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
            :
            <div className="backdrop">
                <div className={className}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
};