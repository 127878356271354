import { getOwnershipBuyoutHistory, getOwnershipBuyoutProposals } from 'service/serviceOwnership';

export const OwnershipAction = {
    SET_OWNERSHIP_HISTORY: 'SET_OWNERSHIP_HISTORY',
    SET_OWNERSHIP_PROPOSALS: 'SET_OWNERSHIP_PROPOSALS',
}

const actionSetOwnershipHistory = (payload) => ({
    type: OwnershipAction.SET_OWNERSHIP_HISTORY,
    data: payload
});

const actionSetOwnershipProposals = (payload) => ({
    type: OwnershipAction.SET_OWNERSHIP_PROPOSALS,
    data: payload
});

export const setOwnershipHistory = () => {
    return (dispatch) => {
        getOwnershipBuyoutHistory().then((result) => {
            dispatch(actionSetOwnershipHistory(result));
        });
    };
};

export const setOwnershipProposals = () => {
    return (dispatch) => {
        getOwnershipBuyoutProposals().then((result) => {
            dispatch(actionSetOwnershipProposals(result));
        });
    };
};
