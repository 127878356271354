import React, { Component } from 'react';
import styled from 'styled-components';
import { BubbleLoader } from 'react-css-loaders';

const Container = styled.div`
    width: 100%;
    min-height: 500px;
    z-index: 9999;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  `;

class Loading extends Component {
    render() {
        const { children } = this.props
        return (
            <Container>
                {children || <BubbleLoader color={'#333'} size={6} />}
            </Container>
        )
    }
}

export default Loading;