import { ArtworksAction } from "action/artworks";

const defaultState = {
  list: [],
  lastAdded: null,
  page: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ArtworksAction.SET_ARTWORKS_LIST:
      return {
        ...state,
        list: action.data,
        page: action.page
      };
    case ArtworksAction.ADD_ARTWORKS_PAGE:
      return {
        ...state,
        list: [...state.list, ...action.data],
        page: action.page
      };

    case ArtworksAction.DEL_ARTWORKS_LIST:
      return {
        ...state,
        list: [],
        page: null
      };
    case ArtworksAction.REG_NEW_ARTWORK:
      return {
        ...state,
        list: [...state.list, action.data],
        lastAdded: action.data
      };
    case ArtworksAction.UPDATE_ARTWORK:
      return {
        ...state,
        list: [...state.list, action.data],
        lastAdded: action.data
      };
    default:
      return state;
  }
};
