import React, { Component } from 'react';

import Icon from 'atom/icon'

import './style';

export default class Overlay extends Component {

    static defaultProps = {
        visible: false,
        className: 'overlay',
        onClose: () => {}
    }

    render() {
        const { visible, className, children, onClose } = this.props;
        return !visible ? null : (
            <div className={className}>
                <div className={`${className}__background`} onClick={() => onClose()} />
                <div className={`${className}__container`}>
                    <Icon className={`${className}__container__close-button`} icon={'fa fa-times-circle'} onClick={() => {onClose()}} />
                    <div>{children}</div>                   
                </div>
            </div>
        );
    }
}