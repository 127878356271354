import Promise from 'core-js/es6/promise';
import { getBrowserLanguages } from 'helper/browser';

export function loadLocale(lang) {
	let currentLang = lang ? lang : getBrowserLanguages();
	localStorage.setItem('locale', currentLang);

	return new Promise((resolve) => {
		//TODO language
		switch (currentLang) {
			case 'it':
			case 'it-IT':
				require.ensure([], () => {
					console.log("it")
					resolve({
						messages: require('../language/it'),
						locale: require('react-intl/locale-data/it'),
						localeName: 'it'
					});
				});
				break;
			default:
				require.ensure([], () => {
					console.log("en")
					resolve({
						messages: require('../language/en'),
						locale: require('react-intl/locale-data/en'),
						localeName: 'en'
					});
				});
		}
	});
}

export const getCurrentLanguage = () => {
	const language = localStorage.getItem('locale');
	switch (language) {
		case 'it':
		case 'it-IT':
			return {id: 'it', label: 'Italiano'}
		case 'en':
		case 'en-US':
		case 'en-UK':
			return {id: 'en', label: 'English'}
		default:
			return {id: 'en', label: 'English'}
	}
}

