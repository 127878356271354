import { fetchJson } from '../../helper/api';
import Alert from '../../components/atom/alert';

export const SchemaAction = {
	SET_SCHEMA: 'SET_SCHEMA',
	DEL_SCHEMA: 'DEL_SCHEMA',
	SET_EXTRAS: 'SET_EXTRAS'
}

const actionSetSchema = (payload) => ({
	type: SchemaAction.SET_SCHEMA,
	data: payload
});

const actionDelSchema = (payload) => ({
	type: SchemaAction.DEL_SCHEMA,
	data: payload
});

const actionSetExtras = (payload) => ({
	type: SchemaAction.SET_EXTRAS,
	data: payload
});

export const setSchema = (data) => {
	return (dispatch) => {
		dispatch(actionSetSchema(data));
	};
};

export const delSchema = () => {
	return (dispatch) => {
		dispatch(actionDelSchema());	
	};
};

export const setExtras = (data) => {
	return (dispatch) => {
		dispatch(actionSetExtras(data));	
	};
};
