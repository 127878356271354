import { getPubAPIEndpointUrl, fetchJson } from '../../helper/api';
import Alert from '../../components/atom/alert';
import config from '../../config';

import Cookies from "js-cookie";
import { getBrowser } from '../../helper/browser';

export const AccountAction = {
	ACCOUNT_HYDRATE: 'ACCOUNT_HYDRATE',
	ACCOUNT_SIGNUP: 'ACCOUNT_SIGNUP',
	ACCOUNT_SIGNIN: 'ACCOUNT_SIGNIN',
	ACCOUNT_SIGNOUT: 'ACCOUNT_SIGNOUT',
	ACCOUNT_FORGOT: 'ACCOUNT_FORGOT',
	ACCOUNT_RECOVER: 'ACCOUNT_RECOVER'
}

const hydrate = (payload = null) => ({
	type: AccountAction.ACCOUNT_HYDRATE,
	data: payload
});

const signIn = (payload) => ({
	type: AccountAction.ACCOUNT_SIGNIN,
	data: payload
});

const signUp = (payload) => ({
	type: AccountAction.ACCOUNT_SIGNUP,
	data: payload
});

const signOut = (payload = null) => ({
	type: AccountAction.ACCOUNT_SIGNOUT,
	data: payload
});

const actionAccountForgot = (payload = null) => ({
	type: AccountAction.ACCOUNT_FORGOT,
	data: payload
});

export const accountHydrate = (callback = Function()) => {
	return (dispatch, getState) => {
		const storageToken = window.localStorage.getItem('auth_token');
		const storageUser = window.localStorage.getItem('user');
		const isLogged = storageToken ? true : false;
		dispatch(hydrate({ storageToken, storageUser, isLogged }));
		return getState().account;
	};
};
export const accountSignin = (username, password) => {
	if (!username || !password) return Alert.error('Insert email and password');


	var urlencoded = new URLSearchParams();
	urlencoded.append('username', username);
	urlencoded.append('password', password);
	urlencoded.append('client_id', config.pubAPI.clientId);
	urlencoded.append('grant_type', 'password');

	const init = {
		method: 'POST',
		body: urlencoded
	}

	return async (dispatch) => {
		return fetchJson('signin', init)
			.then((response) => {
				let browser = getBrowser();
				let auth_token = response.access_token;
				let refresh_token = response.refresh_token;
				let expire_in = new Date().getTime() + ((response.expires_in - 900) * 1000)
				if (browser == "Apple Safari") {
					Cookies.set('auth_token', auth_token);
					Cookies.set('refresh_token', refresh_token);
					Cookies.set('expire_in', expire_in);
				}
				window.localStorage.setItem('auth_token', auth_token);
				window.localStorage.setItem('refresh_token', refresh_token);
				window.localStorage.setItem('expire_in', expire_in);
				dispatch(signIn(response));
				return response;

			}).catch(err => {
				throw err;
			});
	};
};

export const accountSignup = (email, password) => {
	if (!email || !password) return Alert.error('Insert email and password')

	const init = {
		method: 'POST',
		body: JSON.stringify({ email, password })
	};

	return (dispatch) => {
		return fetchJson('signup', init)
			.then((response) => {
				window.localStorage.setItem('auth_token', response.auth_token);
				dispatch(signUp(response));
			}).catch(err => {
				if (err === 409) {
					Alert.error("An account already exists with that email address. Try signing in below.", err);
					return
				}
				console.log('err', err)
			});
	};
};

export const accountForgot = (email) => {
	return (dispatch) => {
		dispatch(actionAccountForgot(email))
	};
};

export const accountRecover = (token, password) => {

	const init = {
		method: 'POST',
		body: JSON.stringify({ token, password })
	}

	return (dispatch) => {
		return fetchJson(getPubAPIEndpointUrl('signup'), init)
			.then((response) => {
				window.localStorage.setItem('sessionId', response.session_id);
				dispatch(signUp(response));
			}).catch(err => {
				if (err === 409) {
					Alert.error("An account already exists with that email address. Try signing in below.", err);
					return
				}
				console.log('err', err)
			});
	};
};

export const accountSignout = () => {
	window.localStorage.removeItem('auth_token');
	window.localStorage.removeItem('refresh_token');
	window.localStorage.removeItem('expire_in');
	window.localStorage.removeItem('user');
	return (dispatch) => {
		dispatch(signOut());
	};
};