import { OwnershipAction } from 'action/ownership';

const defaultState = {
    history: [],
    proposals: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case OwnershipAction.SET_OWNERSHIP_HISTORY:
            return {
                ...state,
                'history': action.data
            }
        case OwnershipAction.SET_OWNERSHIP_PROPOSALS:
            return {
                ...state,
                'proposals': action.data
            }
        default:
            return state;
    }
};