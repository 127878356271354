import React, { PureComponent } from 'react';
import Loadable from 'react-loadable';
import Loading from 'atom/loading';
import { Redirect } from 'react-router'
import { Route } from 'react-router-dom';
import Modal from "../components/atom/modal";
import KycModal from "../components/organism/kycModal";

class LoadingClass extends PureComponent {
    render() {
        const { pastDelay = true } = this.props
        if (this.props.error) {
            return <Loading>Error!</Loading>;
        } else if (this.props.timedOut) {
            return <Loading>Taking a long time...</Loading>;
        } else if (pastDelay) {
            return <Loading />;
        } else {
            return null;
        }
    }
}

export const LazyRouter = (routes) => (
    Object
        .entries(routes)
        .map(([page, requireFn]) => [page, Loadable({ loader: requireFn, loading: () => new LoadingClass() })])
        .map(([key, value]) => ({ [key]: value }))
        .reduce((acc, inc) => ({ ...acc, ...inc }), {})
)

export const PrivateRoute = ({ component: Component, account, user, ...rest }) => (
    <Route {...rest} render={props => {
        if (account.isLogged == null)   {
            return null
        }
        if (user && user.subscription.status === 'canceled') {
            console.log("payment required")
            return <Redirect to={{ pathname: '/payment', state: { from: props.location } }} />
        }

        if (user && user.info) {
            let str = window.location.href;
            let checkProfile = str.includes("profile");
            if (!checkProfile && !user.info.kyc) {
                return (
                    <span>
                        <Modal show={!user.info.kyc}>
                            <KycModal/>
                        </Modal>
                        <Component {...props} />
                    </span>
                )
            }
        }
        return account.isLogged
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }} />
)

export const ReverseRoute = ({ component: Component, account, ...rest }) => (
    <Route {...rest} render={props => {
        return !account.isLogged
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
    }} />
)
