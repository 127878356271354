import { fetchJson, fetchResponse } from "helper/api";
import { getDocument } from 'service/serviceDocument';
import { getBlobUrl } from 'helper/blob'


export const getOwnershipBuyoutProposals = async () => {
    let buyoutProposals;
    try {
        buyoutProposals = await fetchJson("getOwnershipBuyoutProposals")
    } catch (err) {
        console.log("err", err)
        throw err;
    }
    let response = await Promise.all(buyoutProposals.data.map(async item => {
        let blob = await getDocument(item.artwork.main_picture, item.artwork.artwork_code);
        item.artwork.blobUrl = await getBlobUrl(blob);
        return item;
    }))
    return response;
};

export const getOwnershipBuyoutProposal = async (id) => {
    let buyout;
    try {
        buyout = await fetchJson("getOwnershipBuyoutProposal", { id: id })
    } catch (err) {
      console.log("err", err)
      throw err;
    }
    let response = buyout.data;
    let blob = await getDocument(response.artwork.main_picture, response.artwork.artwork_code);
    response.blobUrl = await getBlobUrl(blob);
    return response;
  }

export const getOwnershipBuyoutHistory = async () => {

    let historyData;
    try {
        historyData = await fetchJson("getOwnershipBuyoutHistory")
    } catch (err) {
        console.log("err", err)
        throw err;
    }
    let response = await Promise.all(historyData.data.map(async item => {
        let blob = await getDocument(item.artwork.main_picture, item.artwork.artwork_code);
        item.artwork.blobUrl = await getBlobUrl(blob);
        return item;
    }))
    return response;
};


export const getOwnershipBuyRequestStatus = () => {
    return fetchJson("getOwnershipBuyRequestStatus")
        .then(response => {
            return response.data;
        })
        .catch(e => {
            console.log(e);
            throw e;
        });
};

export const removeBuyoutProposal = (submitted) => {

    const formData = new FormData();
    formData.append('id', submitted.id);
    formData.append('artwork', submitted.artwork);

    const init = {
        method: "DELETE",
        body: formData
    }
    return fetchResponse('removeBuyoutProposal', init)
        .then(response => {
            return response.data;
        })
}

export const postBuyoutProposal = (submitted) => {

    const formData = new FormData();
    formData.append('user', submitted.user);
    formData.append('expire', submitted.expire);
    formData.append('artwork', submitted.artwork);
    formData.append('first_name', submitted.first_name);
    formData.append('amount', submitted.amount);
    formData.append('message', submitted.message);

    const init = {
        method: "POST",
        body: formData
    }
    return fetchJson('postBuyoutProposal', init)
        .then(response => {
            return response.data;
        })
}

export const postBuyoutAnswer = (submitted) => {
    console.log("submitted", submitted);
    const formData = new FormData();
    formData.append('id', submitted.id);
    formData.append('artwork', submitted.artwork);
    formData.append('action', submitted.action);

    const init = {
        method: "POST",
        body: formData
    }
    return fetchJson('postBuyoutAnswer', init)
        .then(response => {
            return response.data;
        })
}