import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

// import BrowserHistory from 'react-router/lib/BrowserHistory';

import { accountHydrate, accountSignout } from 'action/account';
import { setUser } from 'action/user';
import { setArtworksByPage } from 'action/artworks';
import { setNews } from 'action/news';
import { setShareHistory } from 'action/share';
import { setOwnershipProposals, setOwnershipHistory } from 'action/ownership';
import Header from 'page/layout/header';
import Footer from 'page/layout/footer';
import config from 'config';

import EventEmitter from './modules/event-emitter';
import { LazyRouter, PrivateRoute, ReverseRoute } from 'helper/routes';
import history from './history';

import './style';
import * as qs from 'query-string';

// setup moment global
import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');
moment.updateLocale('it', { week: { dow: 1 } });

const loadAnalytics = () => {
  const key = 'G-VMT8KFCRPY';

  const anScript = document.createElement('script');
  anScript.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${key}`,
  );

  const loadScript = document.createElement('script');
  loadScript.innerHTML = `
     window.dataLayer = window.dataLayer || [];
     function gtag(){dataLayer.push(arguments);}
     gtag('js', new Date());

     gtag('config', '${key}');
  `;

  document.body.appendChild(anScript);
  document.body.appendChild(loadScript);

  window.ga =
    window.ga ||
    function() {
      (ga.q = ga.q || []).push(arguments);
    };
  ga.l = +new Date();
  ga('create', `${key}`, 'auto');
};


const Routes = LazyRouter({
  SigninPage: () => import('page/signin'),
  SignupPage: () => import('page/signup'),
  RecoverPage: () => import('page/recover'),
  ActivateUserPage: () => import('page/activateUser'),
  RecoverPasswordPage: () => import('page/recoverPassword'),

  HomePage: () => import('page/manager'), // changed from home (Mario)
  ManagerPage: () => import('page/manager'),
  ArtRightsPage: () => import('page/artRights'),
  FastRightsPage: () => import('page/fastRights'),
  ConciergePage: () => import('page/concierge'),
  MessagesPage: () => import('page/messages'),
  NewsPage: () => import('page/news'),
  InsertArtworkPage: () => import('page/insertArtwork'),
  ArtworkDetails: () => import('page/artworkDetails'),
  ArtworkTagDetails: () => import('page/artworkTagDetails'),
  ArtworkMobileDetails: () => import('page/artworkMobileDetails'),
  RequestValidationPage: () => import('page/requestValidation'),
  SharedArtworkDetailsPage: () => import('page/sharedArtworkDetails'),
  SharingRequestPage: () => import('page/sharingRequest'),
  SharingB2BRequestPage: () => import('page/sharingB2BRequest'),
  ShipmentRequestPage: () => import('page/shipmentRequest'),
  NftRequestPage: () => import('page/nftRequest'),
  SharingHistoryPage: () => import('page/sharingHistory'),
  BuyoutHistoryPage: () => import('page/buyoutHistory'),
  BuyoutProposalPage: () => import('page/buyoutProposal'),
  BuyoutReceivedPage: () => import('page/buyoutReceived'),
  ValidationRequestsPage: () => import('page/validationRequests'),
  BlockchainTransactionPage: () => import('page/blockchainTransaction'),
  PaymentCallbackPage: () => import('page/paymentCallback'),
  PaymentRequiredPage: () => import('page/paymentRequired'),
  SubscriptionExpiredPage: () => import('page/subscriptionExpired'),
  TrialExpiredPage: () => import('page/trialExpired'),
  ForbiddenPage: () => import('page/forbidden'),
  ServerErrorPage: () => import('page/serverError'),
  HelpPage: () => import('page/help'),
  FaqPage: () => import('page/faq'),
  AboutPage: () => import('page/about'),
  ContactsPage: () => import('page/contacts'),
  TermsPage: () => import('page/terms'),
  ProfilePage: () => import('page/profile'),
  SubscriptionPage: () => import('page/subscription'),
  SubscriptionConfirmation: () => import('page/subscriptionConfirmation')
})

class App extends PureComponent {

  componentDidMount() {

    if (this.props.accountHydrate().isLogged) {
      this.initData()
    }

    EventEmitter.on('logout', (args) => {
      this.props.accountSignout()
    });

    EventEmitter.on('paymentRequired', () => {
      window.location.replace("/#/payment_required");
    });

    EventEmitter.on('subscriptionExpired', () => {
      let checkSubscription = window.location.href.includes("subscription_ok");
      if(!checkSubscription) {
        window.location.replace("/#/subscription-expired");
      }
    });

    EventEmitter.on('trialExpired', () => {
      window.location.replace("/#/trial_expired");
    });
    EventEmitter.on('forbidden', () => {
      window.location.replace("/#/forbidden");
    });

    EventEmitter.on('serverError', () => {
      window.location.replace("/#/oops");
    });

    this.timer = null
  }

  initData = () => {
    this.props.setUser();
    this.props.setArtworksByPage();
    // this.props.setNews();
    this.props.setShareHistory();
    this.props.setOwnershipProposals();
    this.props.setOwnershipHistory();
  }

  renderDesktop() {
    const { account, user } = this.props;

    // Once load analytics
    loadAnalytics();

    return (
      <Router >
        <div className="app">
          {account.isLogged}
          <Switch>
            <Route path="/artworkMobile/:artworkId?" component={Routes.ArtworkMobileDetails} account={account} />
            <div>
              {account.isLogged && <Header />}
              <main>
                <Switch>

                  <PrivateRoute path="/home" component={Routes.HomePage} account={account} user={user}/>
                  <PrivateRoute path="/manager/:selectMode?" component={Routes.ManagerPage} account={account} user={user} />
                  <PrivateRoute path="/artrights" component={Routes.ArtRightsPage} account={account} user={user} />
                  <PrivateRoute path="/concierge" component={Routes.ConciergePage} account={account} user={user} />
                  <PrivateRoute path="/messages" component={Routes.MessagesPage} account={account} user={user} />
                  <PrivateRoute path="/news" component={Routes.NewsPage} account={account} user={user} />
                  <PrivateRoute path="/help" component={Routes.HelpPage} account={account} user={user} />
                  <PrivateRoute path="/faq" component={Routes.FaqPage} account={account} user={user} />
                  <PrivateRoute path="/about" component={Routes.AboutPage} account={account} user={user} />
                  <PrivateRoute path="/contacts" component={Routes.ContactsPage} account={account} user={user} />
                  <Route path="/terms" component={Routes.TermsPage} account={account} user={user} />
                  <PrivateRoute path="/fastRights" component={Routes.FastRightsPage} account={account} user={user} />
                  <PrivateRoute path="/insert" component={Routes.InsertArtworkPage} account={account} user={user} />
                  <PrivateRoute path="/artwork/:artworkId?" component={Routes.ArtworkDetails} account={account} user={user} />
                  <PrivateRoute path="/shared-artwork/:artworkId?" component={Routes.SharedArtworkDetailsPage} account={account} user={user} />
                  <PrivateRoute path="/sharing-request/:artworkId?" component={Routes.SharingRequestPage} account={account} user={user} />
                  <PrivateRoute path="/sharing-history" component={Routes.SharingHistoryPage} account={account} user={user} />
                  <PrivateRoute path="/buyout-proposal/:artworkId?" component={Routes.BuyoutProposalPage} account={account} user={user} />
                  <PrivateRoute path="/buyout-history" component={Routes.BuyoutHistoryPage} account={account} user={user} />
                  <PrivateRoute path="/buyout-received/:id?" component={Routes.BuyoutReceivedPage} account={account} user={user} />
                  <PrivateRoute path="/sharing-b2b-request/:artworkId?" component={Routes.SharingB2BRequestPage} account={account} user={user} />
                  <PrivateRoute path="/shipment-request/:artworkId?" component={Routes.ShipmentRequestPage} account={account} user={user} />
                  <PrivateRoute path="/nft-request/:artworkId?" component={Routes.NftRequestPage} account={account} user={user} />
                  <PrivateRoute path="/request-validation/:artworkId?" component={Routes.RequestValidationPage} account={account} user={user} />
                  <PrivateRoute path="/validation-req/:artworkId?" component={Routes.ValidationRequestsPage} account={account} user={user} />
                  <PrivateRoute path="/profile" component={Routes.ProfilePage} account={account} user={user} />
                  <PrivateRoute path="/subscription" component={Routes.SubscriptionPage} account={account} user={user} />
                  <PrivateRoute path="/subscription_ok" component={Routes.SubscriptionConfirmation} account={account} user={user} />
                  <PrivateRoute path="/artworkTag" component={Routes.ArtworkTagDetails} account={account} user={user} />
                  <PrivateRoute path="/blockchain" component={Routes.BlockchainTransactionPage} account={account} user={user} />
                  <PrivateRoute path="/payment_ok" component={Routes.PaymentCallbackPage} account={account} user={user} />
                  <PrivateRoute path="/payment_required" component={Routes.PaymentRequiredPage} account={account} user={user} />
                  <PrivateRoute path="/subscription-expired" component={Routes.SubscriptionExpiredPage} account={account} user={user} />
                  <PrivateRoute path="/trial_expired" component={Routes.TrialExpiredPage} account={account} user={user} />
                  <PrivateRoute path="/forbidden" component={Routes.ForbiddenPage} account={account} user={user} />
                  <PrivateRoute path="/oops" component={Routes.ServerErrorPage} account={account} user={user} />
                  <ReverseRoute path="/login" component={Routes.SigninPage} account={account} user={user} />
                  <Route path="/register" component={Routes.SignupPage} account={account} user={user} />
                  <Route path="/recover" component={Routes.RecoverPage} account={account} user={user} />
                  <Route path="/activateUser" component={Routes.ActivateUserPage} account={account} user={user} />
                  <Route path="/recoverPassword" component={Routes.RecoverPasswordPage} account={account} user={user} />

                  <ReverseRoute path="/" component={Routes.SigninPage} account={account} user={user} />

                </Switch>
              </main>
              <Footer />
            </div>
          </Switch>
        </div>
      </Router>
    )
  }

  renderMobile() {
    return <Routes.LandingPageMobile />
  }

  render() {
    return this.renderDesktop();
  }
}

export default connect(
  (store) => ({
    account: store.account,
    user: store.user
  }),
  (dispatch) => ({
    accountHydrate: () => dispatch(accountHydrate()),
    setUser: () => dispatch(setUser()),
    accountSignout: () => dispatch(accountSignout()),
    setArtworksByPage: () => dispatch(setArtworksByPage()),
    setNews: () => dispatch(setNews()),
    setShareHistory: () => dispatch(setShareHistory()),
    setOwnershipProposals: () => dispatch(setOwnershipProposals()),
    setOwnershipHistory: () => dispatch(setOwnershipHistory()),
  })
)(App);
