import { CurrentArtworkAction } from 'action/artwork';

const defaultState = {
    currentArtwork: {}
};

export default (state = defaultState, action) => {

    switch (action.type) {
        case CurrentArtworkAction.SET_CURRENTARTWORK:
            return {
                ...state,
                'currentArtwork': action.data
            }
        case CurrentArtworkAction.SET_CURRENTARTWORK:
            return {
                ...state,
                'currentArtwork': {}
            }
        default:
            return state;
    }
};