import React, { Component } from 'react';

import Text from 'atom/text';

import './style';

export default class KycModal extends Component {

	static defaultProps = {
		className: 'kyc-modal'
	}

	render() {
		const { className } = this.props;

		return (
			<div className={className}>
				<div className={`${className}__container`} >
					{/*<Text className={`${className}__title`}>_kyc-modal__title</Text>*/}
					{/*<br />*/}
					<Text className={`${className}__subtitle`}>_kyc-modal__subtitle_1</Text>
					<br />
					<Text className={`${className}__subtitle`}>_kyc-modal__subtitle_2</Text>
					<br />
					<div className={`${className}__body d-flex justify-content-center`}>
						<a href="/#/profile?section=1" className={`${className}__active-step-link`}>
							<Text>_kyc-modal__link</Text>
						</a>
					</div>
				</div>
			</div>
		);
	}
}
