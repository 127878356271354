import Parser from 'rss-parser';
 
const  getNews = () => {

    let lastestNewsResponse = [];       

    let parser = new Parser();
    const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

    return parser.parseURL(CORS_PROXY + 'https://www.artrights.me/feed/')
        .then((feed) => {
            feed.items.map((item,index) => {   
                let timestamp = item.pubDate ? item.pubDate : '';
                let title =  item.title ? item.title : '';
                let author = item.creator ? item.creator : '';
                let text = item.contentSnippet ? item.contentSnippet : '';
                let url = item.link ? item.link : '';
                
                let content =  item['content:encoded'];
                var el = document.createElement( 'html' );
                el.innerHTML = content;
                let image = el.querySelector("figure img") ? el.querySelector("figure img").src : el.querySelector("img") ? el.querySelectorAll("img") : 'https://www.artrights.me/ar2/wp-content/uploads/2020/02/mockupdef.png';
                
                lastestNewsResponse.push({
                    'id': index, 
                    'timestamp': timestamp,
                    'title': title,
                    'author': [{ 'name': author, 'surname': ''}], 
                    'main_picture': {'image': image},
                    'text': text,
                    'url': url
                });
            });
            return lastestNewsResponse;
        })
        .catch(e => {
            console.log(e);
        });
}


export {
    getNews
}