import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Text from 'atom/text'

class ExternalLinkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    }
  }

  static defaultProps = {
    items: [],
    className: 'link-list'
  };

  setSelected = (i, parentFunc = () => {}) => {
    this.setState({selected: i});
    parentFunc(i);
  };

  renderItems(items, onSelect = () => {}) {
    const {selected} = this.state;
    const {className, row} = this.props;
    return items.map((param, i) => {

      const selectedClass = param.id === selected ? `${className}__link--selected` : `${className}__link--default`;
      const disabledClass = param.disabled ? `${className}__link--disabled` : '';
      const alignmentClass = row ? `${className}__row` : `${className}__column`;
      const textClass = `${selectedClass} ${disabledClass}`;
      return (
        <li
          key={i}
          className={`${className}__link  ${alignmentClass}`}
          onClick={() => param.disabled ? null : this.setSelected(param.id, onSelect)}
        >
          { param.url
            ?
            <a
              href={param.url}
              target={param.target ? `${param.target}` : '_self'}
            >
              <Text className={textClass} >{param.label}</Text>
            </a>
            :
            null
          }
        </li>
      );
    });
  }

  render() {
    const {items, className, onSelect, style} = this.props;
    return (
      <ul className={className} style={style}>
        { this.renderItems(items, onSelect) }
      </ul>
    );
  }
}

ExternalLinkList.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

export default ExternalLinkList;
