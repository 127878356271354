import { fetchJson, fetchData } from 'helper/api';
import config from '../config';

export const refreshToken = async (refreshToken) => {

    var urlencoded = new URLSearchParams();
    urlencoded.append('client_id', config.pubAPI.clientId);
    urlencoded.append('refresh_token', refreshToken);
    urlencoded.append('grant_type', 'refresh_token');

    const init = {
        method: 'POST',
        body: urlencoded
    }

    return fetchJson('refreshToken', init)
        .then((response) => {
            return response;
        }).catch(err => {
            console.log('err-registration', err);
            return null;
        });
};