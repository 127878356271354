import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Text from 'atom/text';
import Image from 'atom/image';
import LinkList from 'atom/linkList';
import Dropdown from 'molecule/dropdown';

import { accountSignout } from 'action/account';

import './style';

const img_logo = require('assets/logo.svg');
const img_art_manager = require('assets/art-manager.svg');
const img_fast_rights = require('assets/navigator-fastrights.svg');
const img_change = require('assets/change.svg');
const img_swap = require('assets/swap.svg');
const img_art_verify = require('assets/art-verify.svg');
const img_dropdown = require('assets/dropdown.svg');

class Header extends Component {

  static defaultProps = {
    className: "art-header",
    links: [
      { id: 1, label: '_nav_manager', url: '/manager' },
      { id: 2, label: '_nav_rights', url: '/artrights' },
      { id: 3, label: '_nav_concierge', url: '/concierge' },
      // {id:4, label: '_nav_messages', url: '/messages'},
      { id: 5, label: '_nav_news', url: 'https://www.artrights.me/magazine/',
       target: '_blank' } // was /news
    ]
  }

  state = {
    selectedSection: 0,
    dropdownLinks: []
  }

  componentDidMount() {
    this.setState({
      dropdownLinks: [
        { id: 0, label: '_nav_profile', url: '/profile' },
        { id: 2, label: '_nav_profile_subscription', url: '/subscription' },
        { id: 1, label: '_nav_logout', func: () => this.clearLogout() }
      ]
    })
  }

  selectSection = (i) => {
    this.setState({ selectedSection: i });
  }

  clearLogout = () => {
    this.props.accountSignout();
  }

  renderLinks = (links) => {
    return links.map((item, i) => (
      <li key={i} className="nav-item">
        <NavLink className="nav-link" to={item.path} replace>
          <Image img src={item.Image} />
          <Text className={'navigator__link'}>{item.id}</Text>
          {item.notification ? (<span className="fa-layers notice-show"><span className="fa-layers-counter">{item.alert}</span></span>) : null}
        </NavLink>
      </li>
    ));
  }

  // Simula il click su .navbar-toggler se la navbar ha l'opzione show
  // (Chiude la navbar su mobile dopo aver cliccato un link)
  menuToggle = (e) => {
    const navbarToggler = document.getElementsByClassName('navbar-toggler');
    const navbar = document.getElementById('navbarNav');
    if (e.target.tagName === 'SPAN') {
      if (navbar.classList.contains('show')) navbarToggler[0].click();
    }
  };

  render() {
    const { className, links } = this.props;
    const { dropdownLinks } = this.state;
    return (
      <nav className="navbar navbar-artrights navbar-expand-lg navbar-light bg-light fixed-top">
        <NavLink className={'navbar-brand'} to={'/'} replace>
          <Image img src={img_logo} alt="ART RIGHTS" />
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars fa-lg"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav" onClick={this.menuToggle}>
          <LinkList row items={links} />
          <div className={`${className}__dropdown-container`}>
            <Image img className={`${className}__dropdown-image`} src={img_dropdown} />
            <Dropdown links={dropdownLinks} />
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(
  (store) => ({
    account: store.user.info,
    subscription: store.user.subscription
  }),
  (dispatch) => ({
    accountSignout: () => dispatch(accountSignout()),
  })
)(Header);
