import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Text from 'atom/text'

import './style';
import { isNullOrUndefined } from 'util';

export default class LinkList extends Component {

    static defaultProps = {
        items: [],
        className: 'link-list'
    }

    state = {
        selected: 0
    }

    componentDidUpdate(prevProps, prevState) {
        const { forceSelect } = this.props;
        if (forceSelect !== null && forceSelect !== undefined && prevProps.forceSelect !== forceSelect) {
            this.setState({ selected: forceSelect });
        }
    }

    onClickedSelection = (parentFunc = () => { }) => {
        parentFunc();
    }

    setSelected = (i, parentFunc = () => { }) => {
        this.setState({ selected: i });
        parentFunc(i);
    }

    renderItems(items, onSelect = () => { }) {
        const { selected } = this.state;
        const { className, row, resetForceSelect } = this.props;
        return items.map((param, i) => {
            const selectedClass = param.id == selected ? `${className}__link--selected` : `${className}__link--default`;
            const disabledClass = param.disabled ? `${className}__link--disabled` : '';
            const alignmentClass = row ? `${className}__row` : `${className}__column`;
            const textClass = `${selectedClass} ${disabledClass}`;
            return (
                <li
                    key={i}
                    className={`${className}__link  ${alignmentClass}`}
                    onClick={() => {
                        param.disabled ? null : this.setSelected(param.id, onSelect);
                        this.onClickedSelection(resetForceSelect)
                    }}
                >
                        { param.url ?
                            param.target ?
                                <a className={`${className}__pointer`}
                                href={param.url}
                                target={param.target ? `${param.target}` : '_self'}
                                >
                                    <Text className={textClass} >{param.label}</Text>
                                </a>
                            :
                                <NavLink to={param.url} replace>
                                  <Text className={textClass} >{param.label}</Text>
                                </NavLink>
                          :
                            <Text className={textClass}>{param.label}</Text>
                        }
                </li>
            );
        });
    }

    render() {
        const { items, className, onSelect, style } = this.props;
        return (
            <ul className={className} style={style}>
                {this.renderItems(items, onSelect)}
            </ul>
        );
    }
}