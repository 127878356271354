import React, { Component } from 'react';

export default class Icon extends Component {
  static defaultProps = {
      target: '_blank',
      onClick: () => {}
  }

  render() {
    const { className, icon, onClick, onBlur, href, target } = this.props;
    return ( 
      href ?
        <a className={className} href={href} target={target}>
            <i className={icon ? icon : ""}/>
        </a>
        :
        <span key={icon} className={className} onClick={() => onClick()}>
            <i className={icon}/>
        </span>
    );
  }
}