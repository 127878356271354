import { getNews } from 'service/serviceNews';

export const NewsAction = {
    SET_NEWS: 'SET_NEWS',
    DEL_NEWS: 'DEL_NEWS',
}

const actionSetNews = (payload) => ({
    type: NewsAction.SET_NEWS,
    data: payload
});

const actionDelNews = () => ({
    type: NewsAction.DEL_NEWS,
    data: null
  });

export const setNews = () => {
    return (dispatch) => {
        getNews().then((result) => {
            dispatch(actionSetNews(result));
        });
    };
};

export const delNews = () => {
    return async dispatch => {
      dispatch(actionDelNews());
    };
  };