
import React, { Component } from 'react';
import Text from 'atom/text';
import Button from 'atom/button';
export default class Alert extends Component {

	static defaultProps = {
		show: false,
		variant: 'primary',
		dismissFunc: null
	}

	state = {
		display: true,
	}

	hide = () => {
		this.setState({ display: false });
	}


	render() {
		const { show, variant, children, dismissFunc, href } = this.props
		const { display } = this.state;
		return (
			<div>
				{show && display &&
					<div class={`alert alert-${variant} alert-dismissible fade show`} role="alert" ><Text text={children} />
					{href ? <a href={href}> <u>here</u></a> : ''}
						<button type="button" class="close" onClick={dismissFunc ? dismissFunc : this.hide} aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				}
			</div>
		)
	}

}