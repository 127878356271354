import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk'
import { IntlProvider, addLocaleData } from 'react-intl';

import { loadPolyfills } from 'helper/polyfill';
import { loadLocale } from 'helper/locale';
import { rootReducer } from 'reducer';
import App from './App';

import 'assets/style.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));
const appLanguage = localStorage.getItem('locale');
// try {
//     localStorage.setItem('locale', appLanguage ? appLanguage : 'it');
// } catch (e) { /* incognito mode */ }

loadPolyfills().then(() => {
    loadLocale(appLanguage).then(({ messages, locale, localeName }) => {
        addLocaleData(locale);
        ReactDOM.render(
            <IntlProvider locale={localeName} key={localeName} messages={messages}>
                <Provider store={store}>
                    <App />
                </Provider>
            </IntlProvider>,
            document.getElementById('root')
        );
    });
});