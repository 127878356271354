import React, { Component } from 'react';

import Text from 'atom/text';
import Icon from 'atom/icon';
import ExternalLinkList from 'atom/externalLinkList';

import './style';

export default class Footer extends Component {
	static defaultProps = {
		className: 'footer',
		links: [
			{id:0, label: '_nav_help', url: 'https://www.artrights.me/arhelp/', target: '_blank'},
			{id:1, label: '_nav_faq', url: 'https://www.artrights.me/arfaq/', target: '_blank'},
			{id:2, label: '_nav_about', url: 'https://www.artrights.me/arabout/', target: '_blank'},
			{id:3, label: '_nav_contacts', url: 'https://www.artrights.me/arcontacts/', target: '_blank'},
			{id:4, label: '_nav_terms', url: 'https://www.artrights.me/arterms1/', target: '_blank'}
		]
	};

	state = {
		year: '2020'
	};

	componentDidMount() {
		let d = new Date();
		this.setState({year: d.getFullYear()});
	}

	render() {
		const {className, links} = this.props;
		const {year} = this.state;
		return (
			<div className={`${className} d-flex container-fluid align-items-center`}>
				<div className={`${className}__left`}>
					<Text>_footer_copyrights</Text><Text>{year}</Text>
				</div>
				<div className={`${className}__center`}>
					<ExternalLinkList row items={links}/>
				</div>
				<div className={`${className}__right`}>
          <Icon className={`${className}__right__icon`} icon={'fab fa-instagram'} href={'https://www.instagram.com/artrights/'} target={'_blank'} />
          <Icon className={`${className}__right__icon`} icon={'fab fa-linkedin'} href={'https://www.linkedin.com/company/artrights'} target={'_blank'} />
          <Icon className={`${className}__right__icon`} icon={'fab fa-facebook'} href={'https://www.facebook.com/artrights.me/'} target={'_blank'} />
				</div>
			</div>
		);
	}
}