import { getSharingHistory } from 'service/serviceShared';

export const SharedAction = {
    SET_SHARE_HISTORY: 'SET_SHARE_HISTORY',
    DEL_SHARE_HISTORY: 'DEL_SHARE_HISTORY',
}

const actionSetShareHistory = (payload) => ({
    type: SharedAction.SET_SHARE_HISTORY,
    data: payload
});

const actionDelShareHistory = () => ({
    type: SharedAction.DEL_SHARE_HISTORY,
    data: null
  });

export const setShareHistory = () => {
    return (dispatch) => {
        getSharingHistory().then((result) => {
            dispatch(actionSetShareHistory(result));
        });
    };
};

export const delShareHistory = () => {
    return async dispatch => {
      dispatch(actionDelShareHistory());
    };
  };