import { SharedAction } from 'action/share';

const defaultState = {
    history: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SharedAction.SET_SHARE_HISTORY:
            return {
                ...state,
                'history': action.data
            }
        case SharedAction.DEL_SHARE_HISTORY:
            return {
                ...state,
                history: []
            };

        default:
            return state;
    }
};