import { fetchJson, fetchData } from 'helper/api';
import { getBlobUrl } from 'helper/blob'

const getDocument = (document, artwork_code = null) => {
    if (!document) return null;
    const init = {
        method: 'GET',
        headers: {
            'Content-Type': 'form-data'
        },
        id: document.id,
        type: document.type,
        force: 0,
        artwork_code: artwork_code
    }

    return fetchData('getFile', init)
        .then((response) => {
            return response;
        }).catch(e => {
            console.log(e);
            throw e;
        });
};

const getImage = (data) => {

    return getDocument(data).then(async response => {
        return await getBlobUrl(response);
    });
}

const postDocument = (data) => {

    const formData = new FormData();
    formData.append('document', data.document);
    formData.append('title', data.title);
    const init = {
        method: 'POST',
        body: formData
    }
    return fetchJson('postFile', init)
        .then((response) => {
            return response;
        })
        .catch(e => {
            console.log(e);
        });
}

export {
    getDocument,
    postDocument,
    getImage
}