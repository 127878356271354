export default {
	pubAPI: {
		protocol: 'https:',
		host: 'coa.artrights.me',
		clientId: 'Z3XXH26aPtGnsyTbknrG8A2GG9BTdHkKD8ryVOws',
        endpoints: {
            //Auth
            signin: {
                pathname: '/api-auth/token/'
            },
            refreshToken: {
                pathname: '/api-auth/token/'
            },
            signup: {
                pathname: '/api-auth/registration/'
            },
            activateUser: {
                pathname: '/api-auth/activate-user/'
            },
            requestResetPassword: {
                pathname: '/api-auth/reset-password/',
                search: 'email={email}'
            },
            confirmResetPassword: {
                pathname: '/api-auth/reset-password/'
            },
            getFile: {
                pathname: '/{api_root}/{version}/common/file-downloads/',
                search: 'id={id}&type={type}&force-download={force}&artwork_code={artwork_code}'
            },
            //Users and groups
            getMe: {
                pathname: '/{api_root}/{version}/users/me/'
            },
            putMe: {
                pathname: '/{api_root}/{version}/users/me/'
            },
            getUsers: {
                pathname: '/{api_root}/{version}/users/'
            },
            groupList: {
                pathname: '/{api_root}/{version}/users/groups/'
            },
            getOwnerByArtwork: {
                pathname: '/{api_root}/{version}/artworks/artworks-user-data/',
                search: 'artwork={artwork}'
            },
            postIdCard: {
                pathname: '/{api_root}/{version}/users/add-identity-card/'
            },
            deleteIdCard: {
                pathname: '/{api_root}/{version}/users/delete-identity-card/{id}/'
            },
            deleteArtwork: {
                pathname: '/{api_root}/{version}/artworks/artworks/{id}/'
            },
            postSignaturePhoto: {
                pathname: '/{api_root}/{version}/users/set-signature-photo/'
            },
            postProfilePhoto: {
                pathname: '/{api_root}/{version}/users/set-public-photo/'
            },
            getSubgroups: {
                pathname: '/{api_root}/{version}/users/sub-groups/',
            },
            getSubgroupByARGroup: {
                pathname: '/{api_root}/{version}/users/sub-groups/',
                search: 'ar_group={ar_group}'
            },
            //Artworks
            getArtwork: {
                pathname: '{api_root}/{version}/artworks/artworks/{operaId}/',
                search: 'compact={compact}'
            },
            getArtworks: {
                pathname: '{api_root}/{version}/artworks/artworks/',
            },
            getArtworksByPage: {
                pathname: '{api_root}/{version}/artworks/artworks/',
                search: 'page={page}&page_size={page_size}'
            },
            getArtworkByNfc: {
                pathname: '{api_root}/{version}/artworks/artwork-by-nfc/',
                search: 'value={value}'
            },
            getArtowrkByArCode: {
                pathname: '{api_root}/{version}/artworks/artwork-by-artwork-code/',
                search: 'value={value}'
            },
            postArtworksBulkImage: {
                pathname: '{api_root}/{version}/artworks/artwork-bulk-with-image/',
            },
            getAvFormatTypes: {
                pathname: '{api_root}/{version}/artworks/av-format-types/',
            },
            getSignTypes: {
                pathname: '{api_root}/{version}/artworks/sign-types/',
            },
            getTechniqueTypes: {
                pathname: '{api_root}/{version}/artworks/technique-types/',
            },
            getAuthors: {
                pathname: '{api_root}/{version}/artworks/artists/',
            },
            putArtwork: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/',
            },
            // Historical
            getProvenance: {
                pathname: '{api_root}/{version}/artworks/historical/provenance-types/',
            },
            putHistoricals: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/',
            },
            postHistoricalFile: {
                pathname: '{api_root}/{version}/artworks/historical/historical-files/'
            },
            getPropertiesTypes: {
                pathname: '{api_root}/{version}/artworks/historical/property-types/'
            },
            getCommissionTypes: {
                pathname: `{api_root}/{version}/artworks/historical/commission-types/`
            },
            getAuthenticatorSubjectTypes: {
                pathname: `{api_root}/{version}/artworks/historical/authenticator-subject-types/`
            },
            getAuctionTypes: {
                pathname: '{api_root}/{version}/artworks/historical/auction-house/',
            },
            getFreeCirculationPracticeTypes: {
                pathname: '{api_root}/{version}/artworks/historical/free-circulation-practice-types/',
            },
            //Financials
            putFinancials: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/',
            },
            postFinancialFile: {
                pathname: '{api_root}/{version}/artworks/financial/financial-files/'
            },
            getExtraCosts: {
                pathname: '{api_root}/{version}/artworks/financial/extra-cost-types/',
            },
            //Multimedia
            putMultimedia: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/',
            },
            postMultimedia: {
                pathname: '{api_root}/{version}/artworks/multimedia/artworks-multimedia/',
            },
            //Common
            getCurrencies: {
                pathname: '{api_root}/{version}/common/currencies/'
            },
            //BookPress
            postBookPressFile: {
                pathname: '{api_root}/{version}/artworks/book-and-press/book-and-press-files/'
            },
            getPressRelease: {
                pathname: '{api_root}/{version}/artworks/book-and-press/press-release-types/'
            },
            putBookPress: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/',
            },
            // Condition Report
            getStatusType: {
                pathname: '{api_root}/{version}/artworks/condition-report/condition-report-status-type/'
            },
            postConditionReportFile: {
                pathname: '{api_root}/{version}/artworks/condition-report/condition-report-files/'
            },
            putConditionReport: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/'
            },
            // Utility Facility
            postUtilityFacilityFile: {
                pathname: '{api_root}/{version}/artworks/utility-and-facility/utility-facility-files/'
            },
            getContainerType: {
                pathname: '{api_root}/{version}/artworks/utility-and-facility/container-type/'
            },
            getPackagingType: {
                pathname: '{api_root}/{version}/artworks/utility-and-facility/packaging-type/'
            },
            getFillingType: {
                pathname: '{api_root}/{version}/artworks/utility-and-facility/filling-type/'
            },
            getLoanType: {
                pathname: '{api_root}/{version}/artworks/utility-and-facility/loan-type/'
            },
            putUtilityFacility: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/'
            },
            // Loan & Stock
            postLoanFile: {
                pathname: '{api_root}/{version}/artworks/loan-stock/loan-stock-files/'
            },
            putLoan: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/'
            },
            getLoanStatus: {
                pathname: '{api_root}/{version}/artworks/loan-stock/loan-status/'
            },
            // Other
            putOther: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/'
            },
            postOtherFile: {
                pathname: '{api_root}/{version}/artworks/other-artwork-information/other-artwork-information-files/'
            },
            // Exhibition
            postExhibitionFile: {
                pathname: '{api_root}/{version}/artworks/exhibition/exhibition-files/'
            },
            putExhibition: {
                pathname: '{api_root}/{version}/artworks/artworks/{id}/'
            },
            getLocations: {
                pathname: '{api_root}/{version}/artworks/exhibition/exhibition-locations/'
            },

            //Certifications
            postCertificationConfig: {
                pathname: '{api_root}/{version}/certifications/blockchain-configuration/'
            },
            postCertification: {
                pathname: '{api_root}/{version}/certifications/start-certification/'
            },
            getAvailableSections: {
                pathname: '{api_root}/{version}/certifications/blockchain-sections/'
            },
            getArtworkConfiguration: {
                pathname: '{api_root}/{version}/certifications/blockchain-configuration/',
                search: 'artwork={artwork}'
            },
            postCertificationStatus: {
                pathname: '{api_root}/{version}/certifications/last-certification-status/'
            },
            getLastCertification: {
                pathname: '{api_root}/{version}/certifications/download-last-legal-documents-certification/',
                search: 'artwork={artwork}'
            },
            getPdfCertification: {
                pathname: '{api_root}/{version}/certifications/download-last-certification-pdf-file/',
                search: 'artwork={artwork}'
            },
            // Shared
            getSharedArtworks: {
                pathname: '{api_root}/{version}/share/shared-artwork/',
            },
            getSharedArtwork: {
                pathname: '{api_root}/{version}/share/shared-artwork/{operaId}/',
                // search: 'depth=2'
            },
            postSharingRequest: {
                pathname: '{api_root}/{version}/share/share-artwork/',
            },
            getSharingHistory: {
                pathname: '{api_root}/{version}/share/sharing-history/',
            },
            removeShareArtwork: {
                pathname: '{api_root}/{version}/share/remove-share-artwork/',
            },
            // Validation
            getValidationRequests: {
                pathname: '{api_root}/{version}/validations/artwork-to-be-validated/',
            },
            getValidationRequest: {
                pathname: '{api_root}/{version}/validations/artwork-to-be-validated/{operaId}/',
            },
            getValidationRequestHistory: {
                pathname: '{api_root}/{version}/validations/artwork-validated/',
            },
            postArtworkValidation: {
                pathname: '{api_root}/{version}/validations/validate-artwork/'
            },
            postArtworkValidationByEmail: {
                pathname: '{api_root}/{version}/validations/artwork-validation-request/'
            },
            //Subscription
            getUserSubscription: {
                pathname: '{api_root}/{version}/orders/user-subscription/'
            },
            getSubscriptionPackageList: {
                pathname: '{api_root}/{version}/orders/subscription-package-list/'
            },
            prepareSubscription: {
                pathname: '{api_root}/{version}/orders/prepare-subscription/'
            },
            createSubscription: {
                pathname: '{api_root}/{version}/orders/create-subscription/'
            },
            verifySubscription: {
                pathname: '{api_root}/{version}/orders/verify-subscription/'
            },
            //Concierge
            getConciergeList: {
                pathname: '{api_root}/{version}/users/b2b-concierge/'
            },
            getConciergeListBySubgroup: {
                pathname: '{api_root}/{version}/users/b2b-concierge/',
                search: 'sub_group={sub_group}'
            },
            postMessageByUserId: {
                pathname: '{api_root}/{version}/notifications/send-message-by-user-id/'
            },
            postMessageByEmail: {
                pathname: '{api_root}/{version}/notifications/send-message-by-email/'
            },
            postB2BMessage: {
                pathname: '{api_root}/{version}/notifications/send-message-b2b-request/'
            },
            postB2BMessageByUserId: {
                pathname: '{api_root}/{version}/notifications/send-message-b2b-request-by-user-id/'
            },
            // Consumer Products Orders
            getAuthenticators: {
                pathname: '{api_root}/{version}/orders/consumer-product-list/',
            },
            getAuthenticatorsParam: {
                pathname: '{api_root}/{version}/orders/consumer-product-list/',
                search: 'product_type_code={product_type_code}'
            },
            getConsumerProductCart: {
                pathname: '{api_root}/{version}/orders/show-consumer-product-cart/'
            },
            addProductToCart: {
                pathname: '{api_root}/{version}/orders/add-consumer-product-cart/'
            },
            resetProductCart: {
                pathname: '{api_root}/{version}/orders/reset-consumer-product-cart/'
            },
            checkoutProductCart: {
                pathname: '{api_root}/{version}/orders/checkout-consumer-product-cart/'
            },
            verifyProductOrder: {
                pathname: '{api_root}/{version}/orders/verify-order/',
                search: 'payment_id:{payment_id}&payer_id={payer_id}&token={token}'
            },
            // Ownership
            getOwnershipBuyoutProposals: {
                pathname: '{api_root}/{version}/ownership/ownership-buy-request/',
            },
            getOwnershipBuyoutProposal: {
                pathname: '{api_root}/{version}/ownership/ownership-buy-request/{id}/',
            },
            getOwnershipBuyoutHistory: {
                pathname: '{api_root}/{version}/ownership/ownership-buy-request-history/',
            },
            getOwnershipBuyRequestStatus: {
                pathname: '{api_root}/{version}/ownership/ownership-buy-request-status/',
            },
            removeBuyoutProposal: {
                pathname: '{api_root}/{version}/ownership/remove-ownership-buy-request/',
            },
            postBuyoutProposal: {
                pathname: '{api_root}/{version}/ownership/send-ownership-buy-request/',
            },
            postBuyoutAnswer: {
                pathname: '{api_root}/{version}/ownership/ownership-buy-request-action/',
            },
            postShipmentRequest: {
                pathname: '{api_root}/{version}/shipment/request-artwork-shipment/',
            },
            postNftRequest: {
                pathname: '{api_root}/{version}/nft/request-nft/',
            },
            userInfo: {
                pathname: '/api-auth/user'
            },
            sessionAuthorize: {
                pathname: '/api-auth/{version}/session/authorize',
                search: 'app_id={applicationId}'
            },
            braintoken: {
                pathname: '/api-auth/subscriptions/token'
            },

        },
        parameters: {
            // version: '1.0',
            applicationId: 'SC4a7s1AfSB1iv1yEKRaeqVZ',
            api_root: 'api',
            version: 'v1',
        }
    },
    store: {
        apple: {
            url: 'https://itunes.apple.com/it/app/...',
            img: '/assets/AppStore-download-logo.svg'
        },
        android: {
            url: 'https://play.google.com',
            img: '/assets/PlayStore-download-logo.svg'
        }
    },
    mobileBreakPointWidth: 700
}
