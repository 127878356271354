import {getArtworks, getArtworksByPage, postArtwork, putArtwork} from "service/serviceArtworks";

export const ArtworksAction = {
  SET_ARTWORKS_LIST: "SET_ARTWORKS_LIST",
  ADD_ARTWORKS_PAGE: "ADD_ARTWORKS_PAGE",
  DEL_ARTWORKS_LIST: "DEL_ARTWORKS_LIST",
  REG_NEW_ARTWORK: "REG_NEW_ARTWORK",
  UPDATE_ARTWORK: "UPDATE_ARTWORK"
};

const actionSetArtworks = (payload, page) => ({
  type: ArtworksAction.SET_ARTWORKS_LIST,
  data: payload,
  page: page
});

const actionAddArtworksPage = (payload, page) => ({
  type: ArtworksAction.ADD_ARTWORKS_PAGE,
  data: payload,
  page: page
});

const actionDelArtworks = () => ({
  type: ArtworksAction.DEL_ARTWORKS_LIST,
  data: null,
  page: null
});

const actionRegisterArtwork = payload => ({
  type: ArtworksAction.REG_NEW_ARTWORK,
  data: payload
});

const actionUpdateArtwork = payload => ({
  type: ArtworksAction.UPDATE_ARTWORK,
  data: payload
});

export const setArtworks = () => {
  return dispatch => {
    return getArtworks().then( response => {
      dispatch(actionSetArtworks(response, null));
    });
  };
};

export const setArtworksByPage = (page) => {
  return dispatch => {
    return getArtworksByPage(page).then(response => {
      if(!!page && page !== 1){
        dispatch(actionAddArtworksPage(response.data, response.nextPage));
      } else {
        dispatch(actionSetArtworks(response.data, response.nextPage));
      }
    });
  };
};

export const delArtworks = () => {
  return async dispatch => {
    dispatch(actionDelArtworks());
  };
};

export const registerArtwork = data => {
  return async dispatch => {
    return postArtwork(data).then(response => {
      if (response) {
        dispatch(actionRegisterArtwork(response));
        return response;
      }
    });
  };
};

export const updateArtwork = data => {
  return async dispatch => {
    return putArtwork(data).then(response => {
      if (response) {
        dispatch(actionUpdateArtwork(response));
        return response.data;
      }
    });
  };
};
