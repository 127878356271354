import React, { Component } from 'react';
import styled from 'styled-components';
import Overlay from 'atom/overlay';

import './style';

const ImageContainer = styled.div`
    height: ${ ({ height }) => height ? height : 'auto'};
    width: ${ ({ width }) => width ? width : 'auto'};
`;

const Container = styled.div`
    background-image: ${ ({ src }) => src ? 'url('+src+')' : 'none'};
`;

export default class Image extends Component {
    
    state = {
        showData: false,
    };

    toggleShowData = () => {
        this.setState({...this.state, showData: !this.state.showData })
    }


    render() {
        const { className, src, img, overlay } = this.props;
        const { showData } = this.state;
        if(img) {

            if(overlay)
            {
                return (
                    <span>                        
                        <a class="next" onClick={() => { this.toggleShowData() }} >
                            <img className={className} src={src} />
                        </a>
                        <Overlay visible={showData} onClose={this.toggleShowData}>                        
                            <img className={"atom-image__image_thumb"} src={src} />
                        </Overlay>
                    </span>
                )

            } else {
                return (
                    <img className={className} src={src} />
                )
            }
        }
        return (
            <Container className={className} src={src} />
        )
    }
}