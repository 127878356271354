import { getUser } from 'service/serviceUser';

export const UserAction = {
	SET_USER: 'SET_USER',
	DEL_USER: 'DEL_USER'
}

const actionSetUser = (payload) => ({
	type: UserAction.SET_USER,
	data: payload
});

const actionDelUser = (payload) => ({
	type: UserAction.DEL_USER,
	data: payload
});

export const setUser = () => {
	return async (dispatch) => {
		return getUser()
			.then((response) => {
				dispatch(actionSetUser(response));
			});
	};
};

export const delUser = () => {
	return (dispatch) => {
		dispatch(actionDelUser());	
	};
};
