export const CurrentArtworkAction = {
    SET_CURRENTARTWORK: 'SET_CURRENTARWORK',
    DEL_CURRENTARTWORK: 'DEL_CURRENTARWORK'
}

const actionSetCurrentArtwork = (payload) => ({
    type: CurrentArtworkAction.SET_CURRENTARTWORK,
    data: payload
});

const actionDelCurrentArtwork = (payload) => ({
    type: CurrentArtworkAction.SET_CURRENTARTWORK,
    data: payload
});


export const setCurrentArtwork = (data) => {
    return async (dispatch) => {
        dispatch(actionSetCurrentArtwork(data));
    };
};

export const delCurrentArtwork = () => {
    return (dispatch) => {
        dispatch(actionDelCurrentArtwork());
    };
};