import { AccountAction } from 'action/account';

const defaultState = {
    isLogged: true,
    tokenId: '',
    userId: '',
    // username: 'Pinco Pallino'
    username: ''
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case AccountAction.ACCOUNT_HYDRATE:
        /*
            const storageToken = window.localStorage.getItem('auth_token');
            const storageUser = window.localStorage.getItem('user');
            const isLogged = storageUser ? true : false;
            */
            return {
                ...state,
                tokenId: action.data.storageToken,
                userId: action.data.storageUser,
                isLogged: action.data.isLogged
            }
        case AccountAction.ACCOUNT_SIGNIN:
            return {
                ...state,
                tokenId: action.data.auth_token,
                userId: action.data.user,
                isLogged: true
            }
        case AccountAction.ACCOUNT_SIGNUP:
            return {
                ...state,
                userId: action.data.user,
                isLogged: true
            }
        case AccountAction.ACCOUNT_SIGNOUT:
            return {
                ...defaultState,
                isLogged: false
            }
        default:
            return state;
    }
};
