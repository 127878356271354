import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import Text from 'atom/text'
import Icon from 'atom/icon'

import './style';

export default class Dropdown extends Component {

  static defaultProps = {
    containerClass: '',
    className: 'dropdown',
    icon: 'fas fa-chevron-down',
    links: [
      {'id': 0, 'label': 'first option'},
      {'id': 1, 'label': 'second option'},
      {'id': 2, 'label': 'third option'}
    ]
  };

  state = {
    dropdownOpen: false
  };

  toggleDropdown = () => {
    this.setState({dropdownOpen: !this.state.dropdownOpen})
  };

  closeDropdown = () => {
    this.state.dropdownOpen && this.setState({dropdownOpen: false})
  };

  onSelect = (param) => {
    this.props.onSelect && this.props.onSelect(param);
    this.setState({dropdownOpen: false})
  };

  renderItems(items) {
    const {className} = this.props;
    return items.map((param, i) => {
      return (
        <li key={i} className={`${className}__list__li`} onClick={() => this.onSelect(param)}>
          {param.url
            ?
            <NavLink className={`${className}__list__li__link`} to={param.url} replace>
              <Text className={`${className}__list__li__label`}>{param.label}</Text>
            </NavLink>
            :
            param.func
              ?
              <a onClick={() => param.func()} className={`${className}__list__li__link`} to={param.url} replace>
                  <Text className={`${className}__list__li__label`}>{param.label}</Text>
              </a>
              :
              <Text className={`${className}__list__li__label`}>{param.label}</Text>
          }
        </li>
      );
    });
  }

  renderDropdown(items) {
    const {className} = this.props;
    return (
      <div className={`${className}__list`}>
        <div className={`${className}__list__corner`}/>
        <ul className={`${className}__list__ul`}>
          {this.renderItems(items)}
        </ul>
      </div>
    );
  }

  render() {
    const {dropdownOpen} = this.state;
    const {containerClass, className, icon, links} = this.props;
    return (
      <span
        className={`${containerClass} ${className}`}
        onBlur={() => this.closeDropdown()}
        onFocus={() => {}}
      >
        <Icon className={`${className}__dropdown-icon`} onClick={this.toggleDropdown} icon={icon}/>
        {dropdownOpen && this.renderDropdown(links)}
      </span>
    );
  }
}
