import Promise from 'core-js/es6/promise';

export function loadPolyfills() {
	const resolveFetch = () => new Promise((resolve) => {
		if ('fetch' in window) {
			return resolve();
		}

		return require.ensure([], () => {
			require('whatwg-fetch');
			resolve();
		});
	});

	const resolveCoreJs = () => new Promise((resolve) => {
		if (
			'startsWith' in String.prototype &&
			'endsWith' in String.prototype &&
			'includes' in Array.prototype &&
			'assign' in Object &&
			'keys' in Object
		) {
			return resolve();
		}

		return require.ensure([], () => {
			require('core-js');
			resolve();
		});
	});
/*
	const resolveIntl = () => new Promise((resolve) => {
		if ('Intl' in window) {
			return resolve();
		}

		return require.ensure([], () => {
			require('intl');
			require('intl/locale-data/jsonp/en.js');
			require('intl/locale-data/jsonp/it.js');
			resolve();
		});
	});
*/
	return Promise.all([
		resolveFetch(),
		resolveCoreJs(),
//		resolveIntl()
	]);
}
