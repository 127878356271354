import { NewsAction } from 'action/news';

const defaultState = {
    list: [],
};

export default (state = defaultState, action) => {

    switch (action.type) {
        case NewsAction.SET_NEWS:
            return {
                ...state,
                'list': action.data
            }
        case NewsAction.DEL_NEWS:
            return {
                ...state,
                list: []
            };

        default:
            return state;
    }
};