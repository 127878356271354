import { SchemaAction } from 'action/schema';

const defaultState = {
	list: {}
};

export default (state = defaultState, action) => {

	switch (action.type) {
		case SchemaAction.SET_SCHEMA:
			return {
				...state,
				'list': action.data
			}
		case SchemaAction.DEL_SCHEMA:
			return {
				...state,
				'list':{}
			}
		case SchemaAction.SET_EXTRAS:
			let paramName = action.data.paramName;
			return {
				...state,
				'list': {...state.list, properties : {...state.list.properties, [paramName] : {...state.list.properties.technique, 'extras' : action.data}}}
			}
		default:
			return state;
	}
};
